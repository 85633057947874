<template>
  <div class="mask-wrap">
    <div class="dialog-wrap">
      <div class="title-wrap">
        <div class="title" v-if="!$slots.title">{{title}}</div>
        <slot name="title"></slot>
      </div>
      <div class="content-wrap">
        <div class="content" v-if="!$slots.content">{{content}}</div>
        <slot name="content"></slot>
      </div>
      <div class="btn-wrap">
        <div class="btn-line" v-if="!$slots.btn">
          <div class="btn cancel" v-if="showCancelBtn" @click="$emit('cancel')">{{cancelText}}</div>
          <div class="btn confirm" v-if="showConfirmBtn" @click="$emit('confirm')">{{confirmText}}</div>
        </div>
        <slot name="btn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    showCancelBtn: {
      type: Boolean,
      default: false
    },
    showConfirmBtn: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    confirmText: {
      type: String,
      default: '确认'
    }
  },
  computed: {
    showTitle() {
      return !this.$slots.title
    }
  }
}
</script>

<style lang="scss" scoped>
.mask-wrap {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog-wrap {
    width: 351px;
    border-radius: 10px;
    border: 1px solid #707070;
    background: white;
    .title-wrap {
      width: 100%;
      box-sizing: border-box;
      padding-top: 20px;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .title {
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #2C2C2C;
        font-weight: 500;
      }
    }
    .content-wrap {
      width: 100%;
      box-sizing: border-box;
      padding: 0 22px;
      margin-bottom: 27px;
      .content {
        line-height: 25px;
        font-size: 14px;
        color: #2C2C2C;
        text-align: left;
      }
    }
    .btn-wrap {
      width: 100%;
      .btn-line {
        width: 100%;
        height: 50px;
        border-top: 1px solid #E9E9E9;
        display: flex;
        .btn {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
        }
        .cancel {
          border-right: 1px solid #E9E9E9;
          color: #818181;
        }
        .confirm {
          color: #CD001F;
        }
      }
    }
  }
}
</style>