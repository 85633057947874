 <template>
  <div class="addorder-first-container">
    <Header />
    <div class="title">发货信息</div>
    <Form ref="formA" :rows="rows1" v-model="formA" />
    <Form ref="formB" :rows="rows2" style="margin-top: 12px;" v-model="formB" />
    <CustomTextarea label="备注" v-model="remark" :required="false" placeholder="请输入" :showLimit="false" style="margin-top: 12px;" />
    <Form ref="formC" :rows="rows3" style="margin-top: 12px;" v-model="formC" />
    <div class="btn-container">
      <CustomButton size="large" @click="handldToNext">下一步</CustomButton>
      <CustomButton size="large-plain" style="margin-top: 12px;" @click="$router.back()">取消</CustomButton>
    </div>
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import Form from '@/components/common/form/index.vue'
import CustomTextarea from '@/components/common/textArea/index.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import { fetchDictList, fetchDealerList, getDealerById } from '@/api/order'
import { getDealerId, getDealerName } from '@/utils/user'
import { fetchAccount } from '@/api/order'
import { Loading } from 'vant'
let orderSourceIndex,settlementProductIndex;
export default {
  components: {
    Form,
    Header,
    CustomTextarea, 
    CustomButton,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      dealerInfo: {},
      rows: [
        {label: '所属经销商', key: 'belongTo', type: 'picker', required: true, labelString: 'dealerName', valueString: 'dealerId', directReturn: true},
        {label: '发货公司', key: 'company', type: 'picker', required: true, directReturn: true,defaultLabel: 'companyName'}, // eb_factory
        {label: '运输方式', key: 'method', type: 'picker', required: true, directReturn: true,defaultLabel: 'methodName'},
        {label: '整车发货', key: 'isAll', type: 'radio', required: true, data: [
          { key: "是", value: "是" },
          { key: "否", value: "否" },
        ]},
      ],
      formA: {isAll: '否'},
      formB: {
        delay: {
          input: '',
          radio: '否'
        }
      },
      formC: {},
      remark: '',
      beforeFormA: {}
    }
  },
  computed: {
    isX() {
      return this.dealerInfo.zinvoice === 'X';
    },
    rows1() {
      const more = this.formA.method === '10' ? [
        {label: '保价', key: 'baojia', type: 'input', required: true}
      ] : [];
      const tranPrice = this.formA.method === '10' ? {
        label: '运费单价', key: 'tranPrice'
      } : {
        label: '参考运费', key: 'cost', required: true, type: 'input'
      }
      return [
        ...this.rows,
        tranPrice,
        ...more,
      ];
    },
    rows2() {
      return [
        {label: '缓发', key: 'delay', type: 'inputAndRadio', required: true, unit: '天', data: [
          { key: "是", value: "是" },
          { key: "否", value: "否" },
        ]},
        {label: '发票类型', key: 'ovat', type: this.isX ? 'picker' : '', required: true, data: this.isX? ['普通发票', '专用发票'] : ['普通发票'], directReturn: true,hide:!this.isX}
      ]
    },
    rows3() {
      const row = [
        {label: '收货信息', key: 'receive', type: 'picker', required: true, dictType: 'receive', params: {dealerId: getDealerId(), tranType: '', tranMode: this.formA.method}},
        {label: '卸货信息', key: 'discharge', type: 'picker', required: this.formA.method === '20', dictType: 'receive', params: {dealerId: getDealerId(), tranType: '', tranMode: this.formA.method}},
      ]
      return this.formA.method === '10' || this.formA.method === '30' ? [row[0]] : row
    }
  },
  watch: {
    '$route' (to, from) {
      console.log(to);
      console.log(from);
      if (to.name === 'Home') {
        this.$router.go();
      }
    },
    formA: {
      handler() {
        var beforeCost =  this.beforeFormA.cost || '';
        var cost =  this.formA.cost || '';
        var beforeBaojia =  this.beforeFormA.baojia || '';
        var baojia =  this.formA.baojia || '';
        var method =  this.formA.method || '20';
        if (this.formA.belongTo && this.formA.belongTo !== this.dealerInfo.dealerId && this.formA.belongTo !== this.dealerInfo.dealerName) {
          // this.fetchDealerById(this.formA.belongTo);
          this.fetchDealerById(getDealerId());
        }
        if (this.formA.belongTo && this.formA.company && this.formA.method === '10') {
          if (this.beforeFormA.belongTo !== this.formA.belongTo || this.beforeFormA.company !== this.formA.company || this.beforeFormA.method !== this.formA.method) {
            this.fetchAccount()
          }
        }
        this.beforeFormA = {...this.formA}
        if (this.formA.method === '20') {
          this.$set(this.rows3[1].params, 'tranType', this.formA.method)
        }
        if (this.formA.method === '10') {
          this.$set(this.formA, 'cost', '')
        }
        else {
          this.$set(this.formA, 'tranPrice', '')
        }
        this.$set(this.rows3[0].params, 'tranType', this.formA.method)
       
        if (method === '20' || method === '30') {
          if (beforeCost === cost) {
            this.formC = {}
          }
        } else {
          if (beforeBaojia === baojia) {
            this.formC = {}
          }
        }
       
      },
      deep: true
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === 'OrderAddOrderFirst' && from.name !== 'OrderAddOrderSecond' && from.name !== 'AddressBoat') { 
      window.__addOrderFirst_clear__ = true;
    } else {
      window.__addOrderFirst_clear__ = false;
    }

    if (from.params.settlementProduct && from.params.orderSource) {
      settlementProductIndex = from.params.settlementProduct;
      orderSourceIndex = from.params.orderSource;
    } else {
      settlementProductIndex = '';
      orderSourceIndex = '';
    }
    next();
  },
  activated() {
    if (window.__addOrderFirst_clear__) {
      this.clearData();
    }
    this.$set(this.rows, 0, {label: '所属经销商', key: 'belongTo'}) 
    this.$set(this.formA, 'belongTo', getDealerName())
    this.$set(this.formA, 'companyName', this.$route.params.factoryValue);
    this.$set(this.formA, 'company', this.$route.params.factory);
    this.$set(this.formA, 'methodName', this.$route.params.tranModeValue);
    this.$set(this.formA, 'method', this.$route.params.tranMode);
   
  },
  beforeMount() {
    try {
      this.fetchDealer()
      this.fetchCompany()
      this.fetchTransType()
      this.fetchDealerById();
    }
    catch(err) {
      this.$toast('网络问题')
    }
  },
  methods: {
    clearData() {
      this.formA = {isAll: '否'};
      this.formB = {
        delay: {
          input: '',
          radio: '否'
        }
      };
      this.formC = {};
      this.remark = '';
    },
    async fetchDealerById(id) {
      const res = await getDealerById({
        id: id || getDealerId(),
      });
      this.dealerInfo = res.data;
      if (!this.isX) {
        this.formB.ovat = '普通发票';
      }
    },
    async fetchAccount() {
      this.isLoading = true
      try {
        let response = await fetchAccount({
          "factory": this.formA.company,
          "tranMode": this.formA.method,
          "sellerId": getDealerId()
          // "sellerId": this.formA.belongTo
        })
        this.$set(this.formA, 'tranPrice', response.data.tranPrice || '0')
      }
      catch(err) {
        this.$toast(err)
      }
      this.isLoading = false
    },
    async fetchDealer() {
      let response = await fetchDealerList({
        "coordinate": "",
        "page.pageNo": 1,
        "page.pageSize": 100,
      })
      this.$set(this.rows, 0, {...this.rows[0], data: response.data})
    },
    async fetchCompany() {
      let response = await fetchDictList({
        "type": 'eb_factory'
      })
      this.$set(this.rows, 1, {...this.rows[1], data: response.data})
    },
    async fetchTransType() {
      let response = await fetchDictList({
        "type": 'eb_tran_mode'
      })
      this.$set(this.rows, 2, {...this.rows[2], data: response.data})
    },
    handldToNext() {
      if (this.formB.delay.radio === '是' && !/^[+]?\d+$/.test(this.formB.delay.input)) {
        return this.$toast('缓发天数请填写数字');
      }
      if (this.formB.delay.radio === '是' && (!parseInt(this.formB.delay.input) || parseInt(this.formB.delay.input) > 30)) {
        return this.$toast('缓发天数范围为1-30天');
      }
      console.log(this.$route.params.settlementProduct);
      var settlementProduct = this.$route.params.settlementProduct;
      if (settlementProduct && settlementProduct.length > 0 && this.formB.ovat && this.formB.ovat === '专用发票') {
        settlementProduct = settlementProduct.filter(item => !item.materialTax || item.materialTax === '0');
        if (settlementProduct.length > 0) {
          return this.$toast('有机肥仅能开具普票，请在发票类型为普票时下单!');
        }
      }
      if (this.$refs.formA.validate() && this.$refs.formB.validate() && this.$refs.formC.validate()) {
        this.$router.push({
          name: 'OrderAddOrderSecond',
          params: {
            sellerName: this.formA.belongTo || '',
            factory: this.formA.company || '',
            tranMode: this.formA.method || '',
            fhMode: this.formA.isAll === '是' ? 'Y' : 'N',
            cankFee: this.formA.method === '10' ? parseFloat(this.formA.tranPrice) : parseFloat(this.formA.cost),
            delayFlag: this.formB.delay.radio === '是' ? '1' : '0',
            delayDay: this.formB.delay.radio === '是' ? this.formB.delay.input : 0,
            tranAddress: this.formC.receive[0].tranAddress || '',
            cityCode: this.formC.receive[0].cityCode || '',
            cityName: this.formC.receive[0].cityName || '',
            provinceCode: this.formC.receive[0].provinceCode || '',
            provinceName: this.formC.receive[0].provinceName || '',
            areaCode: this.formC.receive[0].areaCode || '',
            areaName: this.formC.receive[0].areaName || '',
            deliverys: this.formC.discharge || [],
            namepath: this.formC.receive[0].namepath || '',
            remark: this.remark || '',
            baojia: this.formA.baojia || '',
            sellerId: getDealerId(),
            tranId: this.formC.receive[0].rowId || '',
            tranTel: this.formC.receive[0].tranTel || '',
            tranConsignee: this.formC.receive[0].tranConsignee || '',
            ovat: !this.formB.ovat||this.formB.ovat === '普通发票' ? '0' : '1',
            settlementProduct: this.$route.params.settlementProduct || settlementProductIndex,
            orderSource: (this.$route.params.orderSource || orderSourceIndex) || '0'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addorder-first-container {
  width: 100%;
  background: #FAFBFC;
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
  .title {
    width: 100%;
    height: 43px;
    box-sizing: border-box;
    padding-left: 24px;
    line-height: 43px;
    font-size: 14px;
    color: #818181;
    text-align: left;
  }
  .btn-container {
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    margin-top: 35px;
  }
}
</style>