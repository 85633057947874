<template>
  <div class="filter-order-page">
    <Header text="确定" @textClick="handleConfirm"></Header>
    <Form style="margin-top: 12px;" :rows="rows" v-model="formA"></Form>
    <Form style="margin-top: 12px;" :rows="rowsB" v-model="formB"></Form>
    <CustomButton confirmLabel="确认" cancelLabel="取消" @confirm="handleConfirm" @cancel="$emit('cancel')"></CustomButton>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithText/index.vue'
import Form from '@/components/common/form/index.vue'
import CustomButton from '@/components/common/button/FooterDoubleButton.vue'
import { fetchDictList } from '@/api/select'

export default {
  components: {
    Header,
    Form,
    CustomButton,
  },
  props: {
    value: {}
  },
  data() {
    return {
      rows: [
        // { key: 'status', label: '订单状态', type: 'picker', valueString: 'dictValue' },
        { key: 'company', label: '发货公司', type: 'picker', valueString: 'dictValue'},
      ],
      rowsB: [
        { key: 'date', label: '下单时间', type: 'dateRange' },
      ],
      formA: {},
      formB: {}
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm')
      this.$emit('input', {
        // status: this.formA.status,
        company: this.formA.company,
        date: this.formB.date
      })
    },
    async fetchOrderStatus() {
      let response = await fetchDictList({type: 'order_status'})
      this.$set(this.rows, 0, { ...this.rows[0], data: response.data })
    },
    async fetchCompany() {
      let response = await fetchDictList({type: 'eb_factory'})
      this.$set(this.rows, 0, { ...this.rows[0], data: response.data })
    }
  },
  async beforeMount() {
    // await this.fetchOrderStatus()
    await this.fetchCompany()
    // this.formB = {
    //   date: new Date()
    // }
  }
}
</script>

<style lang="scss" scoped>
.filter-order-page {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #FAFBFC;
  .btn-wrap {
    position: absolute;
    bottom: 45px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
  }
}
</style>