<template>
  <div class="order-num-wrap">
    <div class="wrap">
      <div class="label">预留号</div>
      <Field
        class="field"
        placeholder="请输入"
        size="small"
        :value="value"
        @input="handleChange"
      ></Field>
      <Button class="search-btn" size="small" @click="handleSearch">
        <span>新增宣传品</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { Field, Button } from "vant";

export default {
  components: {
    Field,
    Button,
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    handleSearch() {
      this.$emit("search");
    },
    handleChange(val) {
      this.$emit("input", val);
    },
  },
}
</script>

<style lang="scss" scoped>
.order-num-wrap {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  background: white;
  .wrap {
    width: 100%;
    display: flex;
    height: 25px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px 0 24px;
    justify-content: space-between; 
    .label {
      font-size: 14px;
      color: #2C2C2C;
      
    }
    .field {
      width: 188px;
      padding: 3px 12px;
      background: #f4f4f5;
      opacity: 1;
      border-radius: 5px 0 0 5px;
      ::v-deep .van-field__body {
        height: 100%;
        .van-field__control {
          height: 100%;
        }
      }
    }
    .search-btn {
      height: 100%;
      font-size: 12px;
      color: #ffffff;
      background: #cd001f;
      border-radius: 0px 5px 5px 0px;
      flex-shrink: 0;
      ::v-deep .van-button__text {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          transform: translateY(0.5px);
        }
      }
    }
  }
}
</style>