<template>
  <div class="progress-component-container">
    <div class="card">
      <div class="item" v-for="(item, index) in progressText" :key="index">
        <div class="icon-container">
          <img class="icon" :class="{checkicon: index <= progress, circleicon: index>progress}" :src="index<=progress ? CheckIcon : CircleIcon" />
        </div>
        <div class="label">{{item}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/images/common/circle-check.png'
import CircleIcon from '@/assets/images/common/ellipse.png'

export default {
  props: {
    nowProgress: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      CheckIcon,
      CircleIcon,
      progressText: ['提交订单', '待发货', '部分发货', '全部发货',]
    }
  },
  computed: {
    progress() {
      let str  = '2ABC'
      return str.indexOf(this.nowProgress)
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-component-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  .card {
    width: 100%;
    box-sizing: border-box;
    height: 91px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    opacity: 1;
    border-radius: 10px;
    padding: 0 14px;
    display: flex;
    .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      box-sizing: border-box;
      padding: 13px 0px 12px;
      justify-content: space-between;
      .icon-container {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .checkicon {
        width: 38px;
        height: 38px;
      }
      .circleicon {
        width: 15px;
        height: 15px;
      }
      .label {

      }
    }
  }
}
</style>