<template>
  <Dialog class="dialog-wrap"
    :showCancelBtn="true"
    @cancel="$emit('cancel')"
    @confirm="handleConfirm"
  >
    <template #content>
      <div class="select-wrap">
        <div 
          class="select-item" 
          :class="{'active': activeIndex[index], 'not-active': !activeIndex[index]}" 
          v-for="(item, index) in promotion" 
          :key="index" 
          @click="handleItemClick(index)"
        >
          {{`${item.mode} ${item.remark} ${item.giftName} 数量${parseInt(item.promotionNumber)}`}}
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/common/dialog/index.vue'

export default {
  components: {
    Dialog
  },
  props: {
    promotion: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      activeIndex: {}
    }
  },
  methods: {
    handleItemClick(index) {
      if (index in this.activeIndex) {
        this.$delete(this.activeIndex, index)
      }  
      else {
        this.$set(this.activeIndex, index, true)
      }
    },
    handleConfirm() {
      if (Object.keys(this.activeIndex).length === 0) {
        this.$toast('请选择一个优惠政策')
      }
      else {
        this.$emit('confirm', this.activeIndex)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrap {
  .select-wrap {
    width: 100%;
    box-sizing: border-box;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    .active {
      color: white;
      background: #CD001F;
    }
    .not-active {
      color: #2C2C2C;
      background: #F7F7F7;
    }
    .select-item {
      width: 100%;
      min-height: 29px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: left;
      border-radius: 5px;
      margin-bottom: 6px;
      font-size: 14px;
      box-sizing: border-box;
      padding: 0 12px;
    }
  }
}
</style>