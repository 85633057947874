<template>
  <div class="addorder-third-page">
    <Header text="确定" @textClick="handleConfirm"></Header>
    <OrderNumber style="margin-bottom: 12px;" v-model="rsnum" @search="searchRsnum"/>
    <Publicity v-for="(item, index) in showList" :key="index" :info="item" v-model="listValue" />
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithText/index.vue'
import OrderNumber from './components/orderNumber.vue'
import Publicity from './components/publicity.vue'
import { fetchPublicityList,fetchCompanyPublicitysList } from '@/api/order'
import { getDealerId } from '@/utils/user'
import { Loading } from 'vant'

export default {
  components: {
    Header,
    OrderNumber,
    Publicity,
    Loading,
  },
  data() {
    return {
      list: [],
      rsnum: '',
      listValue: {},
      isLoading: false
    }
  },
  computed: {
    showList() {
      return this.list.filter(item=>{
        return item.display && item.rsnum.search(this.rsnum) !== -1
      })
    }
  },
  watch: {
  },
  beforeMount() {
    this.params = this.$route.params
    this.fetchPublicity()
  },
  methods: {
    async fetchPublicity() {
      this.isLoading = true
      let response = await fetchPublicityList({
        "dealerId": getDealerId(),
        "factory": this.params.factory || '10'
      })
      this.list = response.data
      this.isLoading = false
    },
    handleConfirm() {
      let ret = []
      this.list.map(item=>{
        let applyNum = this.listValue[`${item.rsnum}-${item.materialCode}`] ? this.listValue[`${item.rsnum}-${item.materialCode}`].applyNum : 0
        if (applyNum > 0){
          let temp = {...item, materialId: item.materialCode, applyNum}
          delete temp.materialCode
          ret.push(temp)
        }
      })
      this.$emit('confirm')
      this.$emit('input', ret)
    },
    async searchRsnum() {
      if (null == this.rsnum || undefined == this.rsnum || '' == this.rsnum) {
        this.$toast('新增宣传品时预留号不能为空');
        return;
      }
      this.isLoading = true
      try {
        let response = await fetchCompanyPublicitysList({
          "dealerId": getDealerId(),
          "factory": this.params.factory || '10',
          "rsnum": this.rsnum
        });
        this.list = response.data;
      } catch (err) {
        this.isLoading = false;
        this.$toast('服务器内部错误！');
        return;
      }
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.addorder-third-page {
  width: 100%;
  height: 100vh;
  background:  #FAFBFC;
  position: fixed;
  left: 0;
  top: 0;
  overflow: scroll;
  .loading-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>