<template>
  <div class="order-index-page">
    <Header 
      type="add" 
      :icons="[ {icon: AddIcon, event: 'add'}]"
      @add="$router.push({name: 'OrderAddOrderFirst', params: {seller: 'self'}})"
    />
    <Tabs v-show="!showFilter" class="tabs" v-model="active" @click="onClickStatus" animate sticky line-width="30px">
      <Tab title="待发货" title-class="title"/>
      <Tab title="已部分发货" title-class="title"/>
      <Tab title="全部发货" title-class="title"/>
      <Tab title="全部订单" title-class="title"/>
    </Tabs>
    <div class="search-wrap">
      <SearchWrap v-model="searchTotal" @search="search"></SearchWrap>
      <div class="sort-wrap">
        <img
            src="../../assets/images/common/filter.png"
            alt=""
            @click="showFilterDialog"
        />
      </div>
    </div>
    <div class="card-list">
      <Card class="card" v-for="(item, index) in list" :key="index" :content="item" :hasStatus="true" :status="status[index]" >
        <div class="detial-btn-wrap">
          <CustomButton class="btn invoice" size="mini" @click="$router.push({path: `/info/invoiceInfo?id=${originList[index].sapOrderCode}`})">电子发票</CustomButton>
          <CustomButton class="btn logistics" size="mini" @click="$router.push({path: `/info/carrierInfo?id=${idList[index]}`})">物流信息</CustomButton>
          <CustomButton class="btn detail" size="mini" @click="$router.push({path: `/order/detail?id=${idList[index]}&date=${originList[index].addDate}&sapcode=${originList[index].sapOrderCode}`})">订单详情</CustomButton>
        </div>
      </Card>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span class="no-more" slot="no-more">
        暂无更多
      </span>
    </infinite-loading>
    <FilterOrder
      v-if="showFilter"
      v-model="filterData"
      @confirm="handleFilterConfirm"
    >
    </FilterOrder>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithIcon/index.vue'
import Card from '@/components/common/card/index.vue'
import AddIcon from '@/assets/images/common/add.png'
import FilterIcon from '@/assets/images/common/filter-white.png'
import CustomButton from '@/components/common/button/CustomButton.vue'
import SearchWrap from "@/components/common/search";

import InfiniteLoading from 'vue-infinite-loading'
import { fetchOrderList } from '@/api/order'
import FilterOrder from './filterOrder.vue'
import dayjs from 'dayjs'
import { Tab,Tabs  } from 'vant';


export default {
  components: {
    Header,
    Card,
    CustomButton,
    InfiniteLoading,
    FilterOrder,
    Tab,
    Tabs,
    SearchWrap
  },
  data() {
    return {
      AddIcon,
      FilterIcon,
      status: [],
      idList: [],
      active: 0,
      showFilter: false,
      loading: false,
      searchTotal: '',
      filterData: { company: '', date: '', status: '', searchTotal: ''},
      config:[
        {
          label: '订单号',
          field: 'sapOrderCode',
          color: '#2C2C2C',
        },
        {
          label: '订单日期',
          field: 'addDate',
          color: '#2C2C2C'
        },
        {
          label: '业务员',
          field: 'personName',
          color: '#2C2C2C'
        },
        {
          label: '经销商',
          field: 'sellerName',
          color: '#2C2C2C'
        },
        {
          label: '经销商编号',
          field: 'sellerId',
          color: '#2C2C2C'
        },
        {
          label: '业务员电话',
          field: 'personPhone',
          color: '#2C2C2C'
        },
        {
          label: '优惠金额',
          field: 'orderDiscountAllPrice',
          color: '#2C2C2C'
        },
        {
          label: '总金额',
          field: 'orderAllPrice',
          color: '#2C2C2C'
        },
      ],
      list: [],
      pageNo: 1,
      pageSize: 10,
      total: 0, 
      originList: []
    }
  },
  deactivated() {
    this.resetList()
  },
  activated() {
    this.searchTotal = '';
    this.resetList()
    this.active = Number(this.$route.query.active) ? Number(this.$route.query.active) : 0;
    this.onClickStatus(this.active);
  },
  methods: {
    resetList() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.status = [];
      this.idList = [];
      this.filterData = []
      this.pageNo = 1;
      this.total = 0;
    },
    handleFilterConfirm() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = []
      this.status = []
      this.idList = []
      this.originList = []
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchList() {
      let response = []
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "factory": this.filterData.company,
        "addDateStart": this.filterData.date && this.filterData.date[0] ? dayjs(this.filterData.date[0]).format('YYYYMMDD') : '',
        "addDateEnd": this.filterData.date && this.filterData.date[1] ? dayjs(this.filterData.date[1]).format('YYYYMMDD') : '',
        "orderStatus": this.filterData.status,
        "searchTotal": this.searchTotal
      }
      response = await fetchOrderList(data)
      this.list = this.list.concat(this.formatList(response.data) || []);
      this.originList = this.originList.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0;
    },
    formatList(data) {
      let ret = []
      let status = this.status
      let idList = this.idList
      data.map && data.map(dataItem=>{
        let oneItem = []
        this.config.map(item=>{
          oneItem.push({
            ...item,
            value: dataItem[item.field],
          })
        })
        status.push({text: dataItem.orderStatus, color: '#CD001F'})
        idList.push(dataItem.rowId)
        ret.push(oneItem)

      })
      this.status = status
      this.idList = idList
      return ret
    },

    onClickStatus(index) {
      
      if (index === 0) {
        this.filterData.status = "A";
      } else if (index === 1) {
        this.filterData.status = "B";
      } else if (index === 2) {
        this.filterData.status = "C";
      } else {
        this.filterData.status = "";
      }
      this.handleFilterConfirm();
    },

    showFilterDialog() {
      this.showFilter=true
    },
    search() {
      this.filterData.searchTotal = this.searchTotal;
      this.handleFilterConfirm();
    }
    
    
  }
}
</script>

<style lang="scss" scoped>
.order-index-page {
  background: #FAFBFC;
  width: 100%;
  min-height: 100vh; 
  box-sizing: border-box;

  .tabs {
   
    ::v-deep .van-tabs__wrap {
      
      height: 40px;
      .van-tabs__nav {
        padding-left: 18px;
        .van-tab {
          height: 45px;
          font-size: 16px;

          .van-tab__text {
            display: block;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }

  .search-wrap {
    padding: 10px 24px 10px 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      flex-shrink: 0;
      img {
        height: 14px;
        width: auto;
        margin-left: 20px;
      }
    }
  }
  
  
  .card-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    margin-top: 12px;
    .card {
      margin-bottom: 12px;
      .detial-btn-wrap {
        text-align: right;
        .btn {
          color: #ffffff;
          background: #cd001f;
          font-size: 0.75rem;
          box-shadow: 0 0 10px rgba(235, 235, 235, 0.62);
          border-radius: 20px;
          padding: 0 10px;
          height: 30px;
        }
        
        .invoice {
          background: #cd001f;
        }
        
        .logistics {
          background: #FFA240;
          margin-left: 10px;
          margin-right: 10px;
        }
        
        .detail {
          background: #33CC67;
        }
      }
    }
  }
  .no-more {
    font-size: 14px;
    color: #323232;
  }
}
</style>