<template>
  <div class="label-value-container" :class="{oneline: type!=='two', twoline: type==='two', half: type==='half'}">
    <div class="label">{{label}}</div>
    <div class="value" :style="{color: valueColor ? valueColor : '#2C2C2C'}">{{value}}</div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'one' // one two
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    valueColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.label-value-container {
  font-size: 14px;
  margin-bottom: 5px;
  flex-shrink: 0;
  .label {
    color: #91939A;
  }
  .value {
    color: #2C2C2C;
  }
}
.oneline {
  width: 100%;
  height: 20px;
  display: flex;
  .label {

  }
  .value {
    margin-left: 8px;
  }
}
.twoline {
  width: 100%;
  .label {
    margin-bottom: 5px;
  }
  .value {
    min-height: 20px;
    line-height: 20px;
  }
}
.half {
  width: 50%;
  flex-shrink: 0;
  height: 20px;
  display: flex;
  .label {

  }
  .value {
    margin-left: 8px;
  }  
}
</style>