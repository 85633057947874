<template>
  <div class="block-wrap">
    <div class="title" v-if="title && list.length !== 0">{{title}}</div>
    <div class="block-container" v-for="(data, index) in list" :key="index">
      <div class="block-name" v-if="blockName">{{data[blockName]}}</div>
      <LabelValue v-for="item in config" :key="item.field" :type="item.type" :label="item.label" :value="item.mapping ? item.mapping[data[item.field]] : data[item.field]" :valueColor="item.valueColor" />
    </div>
  </div>
</template>

<script>
import LabelValue from './labelValue.vue'

export default {
  components: {
    LabelValue,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    config: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    blockName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.block-wrap {
  text-align: left;
  .title {
    height: 41px;
    padding: 12px 0 0 24px;
    font-size: 14px;
    color: #91939A;
    box-sizing: border-box;
  }
  .block-container {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 24px 15px;
    background: white;
    display: flex;
    flex-wrap: wrap;
  }
  .block-name {
    width: 100%;
    flex-shrink: 0;
    height: 21px;
    text-align: left;
    font-size: 15px;
    color: #2C2C2C;
    line-height: 21px;
    margin-bottom: 10px;
  }
}
</style>