<template>
  <div class="publicity-wrap" :class="{active, 'not-active': !active}">
    <div class="title">{{info.materialName || '--'}}</div>
    <div class="line">
      <div class="label-value-wrap">
        <div class="label">预留号</div>
        <div class="value">{{info.rsnum || '--'}}</div>
      </div>
      <div class="label-value-wrap">
        <div class="label">物料编号</div>
        <div class="value">{{info.materialCode || info.materialId || '--'}}</div>
      </div>
    </div>
    <div class="line">
      <div class="label-value-wrap">
        <div class="label">未发申请数量</div>
        <div class="value">{{info.availableQty || '--'}}</div>
      </div>
    </div>
    <div class="line">
      <div class="label-value-wrap">
        <div class="label">随车数量</div>
        <div class="count-wrap" :style="{'justify-content': show ? 'center' : 'space-between'}">
          <div class="circle sub" v-if="!show" @click="sub">
            <img class="icon" :src="active ? WhiteCircleSub : RedCircleSub" />
          </div>
          <div class="count-num">{{show ? info.applyNum || 0 : count}}</div>
          <div class="circle add" v-if="!show" @click="add">
            <img class="icon" :src="active ? WhiteCircleAdd : RedCircleAdd" />
          </div>
        </div>
      </div>
      <div class="label-value-wrap">
        <div class="label">单位</div>
        <div class="value">{{info.materialUnit || '--'}} </div>
      </div>
    </div>
    <img class="delete-icon" :src="DeleteIcon" @click="handleClickDelete" />
  </div>
</template>

<script>
import RedCircleAdd from '@/assets/images/common/red-circle-add.png'
import RedCircleSub from '@/assets/images/common/red-circle-sub.png'
import WhiteCircleAdd from '@/assets/images/common/white-circle-add.png'
import WhiteCircleSub from '@/assets/images/common/white-circle-sub.png'
import DeleteIcon from '@/assets/images/common/trash.png'
 
export default {
  props: {
    value: {},
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      RedCircleAdd,
      RedCircleSub,
      WhiteCircleAdd,
      WhiteCircleSub,
      DeleteIcon,
    }
  },
  computed: {
    count() {
      return !this.show && this.value[`${this.info.rsnum}-${this.info.materialCode}`] ? this.value[`${this.info.rsnum}-${this.info.materialCode}`].applyNum : 0
    }
  },
  methods: {
    add() {
      if (!this.show && this.count < parseInt(this.info.availableQty)) {
        this.$set(this.value, [`${this.info.rsnum}-${this.info.materialCode}`], {
          publicityId: this.info.materialCode, 
          rsnum: this.info.rsnum, 
          applyNum: this.count+1
        })
        this.$emit('input', this.value)
      }
    },
    sub() {
      if (!this.show && this.count > 0) {
        this.$set(this.value, [`${this.info.rsnum}-${this.info.materialCode}`], {
          publicityId: this.info.materialCode, 
          rsnum: this.info.rsnum, 
          applyNum: this.count-1
        })
        this.$emit('input', this.value)
      }
    },
    handleClickDelete() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
.publicity-wrap {
  width: 100%;
  min-height: 120px;
  box-sizing: border-box;
  padding: 9px 24px 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 1px ;
  background: white;
  position: relative;
  .delete-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 30px;
    bottom: 10px;
  }
  &.not-active {
    .title {
      color: #2C2C2C;
    }
    .line {
      .label-value-wrap {
        .label {
          color: #818181;
        }
        .value {
          color: #2C2C2C;
        }
        .count-wrap {
          .circle {
            background: #CD001F;
            color: white;
          }
          .count-num {
            color: #2C2C2C;
          }
        }
      }
    }
  }
  &.active {
    background: #CD001F;
    .title {
      color: #FAFBFC;
    }
    .line {
      .label-value-wrap {
        color: #FAFBFC;
        .count-wrap {
          .circle {
            background: #FAFBFC;
            color: #CD001F;
          }
        }
      }
    }
  }

  .title {
    min-height: 23px;
    line-height: 23px;
    font-size: 16px;
    font-weight: 500;
  }
  .line {
    display: flex;
    .label-value-wrap {
      flex: 1;
      display: flex;
      height: 20px;
      line-height: 20px;
      margin-top: 6px;
      font-size: 14px;
      .value {
        margin-left: 6px;
      }
      .count-wrap {
        width: 65px;
        display: flex;
        margin-left: 9px;
        align-items: center;
        .icon {
          width: 100%;
          height: 100%;
        }
        .circle {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
}
</style>